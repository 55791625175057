/* @import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap); */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Source+Serif+4:opsz,wght@8..60,600&display=swap'); */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
* {
  font-size: 62.5%;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  --footer-padding: 100px;
  font-family: 'Joan', serif;
  scroll-behavior: smooth;
  word-spacing: 4px;
}

.loader-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .loader-container {
    background: rgba(255,255,255,0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

#loading{
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  mix-blend-mode: color-burn;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.App-logo-container {
  height: 60px;
  display: flex;
  cursor: pointer;
}

.App-logo {
  width: auto;
  padding: 0px 20px;
}

.App-heading {
  width: auto;
  padding: 10px 0px;
}

.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gradiant-container {
  width: 90%;
  padding-bottom: 140px;
  margin: 20px 20px -50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 88%, 0 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 88%, 0 100%);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 21%,
    rgba(204, 195, 176, 1) 100%
  );
}

.bottom {
  margin: 0px;
  padding-bottom:50px;
  -webkit-clip-path: polygon(0 12%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 12%, 100% 0, 100% 100%, 0 88%);
}

.gradiant-heading {
  font-size: 4rem;
  color: #cd6727;
  padding: 20px;
}

.gradiant-image-container-wrapper {
  width: 75%;
  display: flex;
}
.gradiant-image-container {
  position: relative;
  width: 33%;
  height: 400px;
  margin: 10px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  overflow: hidden;
  border-radius: 5px;
}

.center-image {
  height: 450px !important;
}

.gradiant-image-container:hover .gradiant-img {
  transform: scale(1.2);
  cursor: pointer;
}
.gradiant-img {
  width: unset;
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
  transition: 0.2s all ease-in-out;
}

.title-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  width: 100%;
  height: 100px;
  background-color: rgba(45, 45, 45, 0.71);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.title-container span {
  font-size: 5rem;
  color: #faf7f0;
  text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
}
.right-arrow {
  width: 31px !important;
  height: 30px !important;
  padding-left: 15px;
  margin-top: 15px;
}

.parent-container {
  width: 90%;
  padding: 140px 0px;
  margin: 20px;
  -webkit-clip-path: polygon(0 12%, 100% 0, 100% 100%, 0 88%);
  clip-path: polygon(0 12%, 100% 0, 100% 100%, 0 88%);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 21%,
    rgba(204, 195, 176, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.alternate {
  -webkit-clip-path: polygon(0 0, 100% 12%, 100% 88%, 0 100%);
  clip-path: polygon(0 0, 100% 12%, 100% 88%, 0 100%);
  margin-top: -50px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 21%,
    rgba(204, 195, 176, 1) 100%
  );
}

.primary-image-conatiner {
  width: 40%;
}

.primary-image-conatiner img {
  width: -webkit-fill-available;
}

.primary-text-conatiner {
  width: 50%;
  background-color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

h1 {
  font-size: 4rem;
  color:#cd6727
}

h2 {
  font-size: 3rem;
  color:#cd6727
}

p {
  font-size: 2rem;
  line-height: 2rem;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 4rem;
  width: 100%;
  text-align: right;
  padding-right: 20px;
  cursor: pointer;
  height: 60px;
}

.member-conatiner{
  display: flex;
  flex-direction: row;
  width:90%;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 100px;
}
.member-image-container{
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.member-image{
  width: 60%;
  box-shadow: 0 0px 0px rgb(0 0 0 / 30%), 0 5px 10px rgb(0 0 0 / 22%);
  border-radius: 5px;
}

.member-name{
  display: flex;
  justify-content: center;
  text-align: center;
  color: #404349;
  font-size: 2rem;
  padding-top: 20px;
}

.member-designation{
  color: #404349;
  font-size: 1.5rem;
  padding-top: 5px;
}

.path-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fcfcfc;
}

p {
  font-size: 1.5rem;
}

.path-title {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 2rem;
  text-align: left;
  color: #cd6727;
}

.path-img-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.path-img-container {
  width: 90%;
  margin: 20px 0px;
}

.path-image-1 {
  background-size: cover;
  width: 100%;
  box-shadow: 0 0px 0px rgb(0 0 0 / 30%), 0 5px 10px rgb(0 0 0 / 22%);
}

.path-text-container {
  width: 90%;
}

.heading {
  font-size: 2rem;
  color: #cd6727;
}

.sub-heading {
  font-size: 1.5rem;
  color: #cd6727;
}

.classes-card {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.classes-image-container {
  width: 100%;
  box-shadow: 0 0px 0px rgb(0 0 0 / 30%), 0 5px 10px rgb(0 0 0 / 22%);
  border-radius: 5px;
}

.classes-image{
  width: 100%;
  border-radius: 5px;
}

.classes-description-container {
  width: 85%;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.classes-description {
  font-size: 2rem;
  font-weight: 600;
  color: #cd6727;
  
}  

.events-container{
  display: flex;
  width:100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.event-box{
  display: flex;
  width:90%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0px 30px 10px;
}

.event-images-container{
  display: flex;
  flex-direction: column;
}

.event-title{
  width:100%;
  font-size: 2rem;
  color: #cd6727;

}

.event-images{
  width: 100%;
  margin: 20px 0px;
  box-shadow: 0 0px 0px rgb(0 0 0 / 30%), 0 5px 10px rgb(0 0 0 / 22%);
  border-radius: 5px;
}

.books-main-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, #fef9d7 0%, #fdfcfc 100%);
}

.book-card-container {
  display: grid;
  width: 90%;
  margin: 20px 0px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px 40px;
  justify-content: center;
}

.book-card {
  width: 100%;
  border-radius: 5px;
  background-color: #fcfcfc;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* padding-bottom: 50px; */
  box-shadow: 0 0px 0px rgb(0 0 0 / 30%), 0 5px 10px rgb(0 0 0 / 22%);
  position: relative;
}

.book-image {
  margin-top: 20px;
  width: 100%;
}

.slick-slide img {
  padding: 0px calc((100% - 190px) / 2);
}

.book-description-container {
  width: 85%;
  padding: 50px 0px;
}

.book-description {
  font-size: 1.5rem;
  color: #28231e;
}

.buynow-link {
  display: flex;
  position: absolute;
  font-size: 2rem;
  text-decoration: none;
  color: #cd6727;
  bottom: 5%;
}

.amazon-logo {
  width: 100px;
  padding-left: 5px;
}


@media screen and (min-width: 60em) {
  .event-box{
      flex-direction: column;
  }

  .event-title{
     font-size: 3rem;
  }

  .event-images-container{
      justify-content: space-around;
      flex-direction: row;
      width:90%
  }

  .event-images{
      width: 40%;
      margin: 20px;
  }
}

@media screen and (min-width: 60em) {
  .path-img-text-wrapper {
    flex-direction: row;
    width: 90%;
  }
  .path-img-container {
    margin-right: 20px;
    display: flex;
    width: 50%;
    align-items: center;
  }
  .path-image-1 {
    width: 90%;
  }
}

@media screen and (max-width: 80em) {
  .title-container span {
    font-size: 3rem;
  }
}

@media screen and (max-width: 60em) {
  .gradiant-container {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    margin-bottom: 40px;
    padding-bottom: 50px;
  }
  .gradiant-heading {
    padding: 0px;
    color:#cd6727
  }
  .gradiant-image-container-wrapper {
    width: 90%;
    flex-direction: column;
  }
  .gradiant-image-container {
    width: 100%;
    margin: 10px 0px;
    border-radius: 5px;
  }
  .gradiant-img {
    height: 400px !important;
    width: 100%;
  }
  .center-image {
    height: 400px !important;
  }
  .title-container {
    bottom: 0px !important;
  }
  .parent-container {
    flex-direction: column;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    height: max-content;
    padding: 20px 0%;
  }
  .alternate {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    margin-top: 40px;
  }
  .primary-text-conatiner {
    width: 80%;
  }
  .primary-image-conatiner {
    width: 60%;
  }
  
}

@media screen and (max-width: 37.5em) {
  .primary-image-conatiner {
    width: calc(80% + 38px);
  }
  .member-conatiner{
    flex-direction: column;
   }
  .member-image-container{
    padding-bottom: 50px;
    width:80%;
  }
  .member-image{
    width: 100%;
  }
  .member-name{
    font-size: 1.5rem;
  }
  .member-designation{
    font-size: 1.5rem;
  }
}

