.sidebar {
  border: 1px solid #28231e;
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  flex-direction: column;
  top: 0%;
  right: 0%;
  height: 100vh;
  z-index: 1;
  display: none;
  overflow-y: scroll;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 21%,
    rgba(204, 195, 176, 1) 100%
  );
  position: absolute;
  box-shadow: 0 0px 0px rgb(0 0 0 / 30%), 0 5px 10px rgb(0 0 0 / 22%);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.sidebar-transition-enter {
  display: flex !important;
  opacity: 0;
  width: 0px;
}
.sidebar-transition-enter-active {
  display: flex !important;
  opacity: 1;
  width: 70%;
  transition: opacity 300ms, width 300ms;
}
.sidebar-transition-enter-done {
  width: 70%;
  opacity: 1;
  display: flex !important;
}
.sidebar-transition-exit {
  opacity: 1;
  width: 70%;
  display: flex !important;
}

.sidebar-transition-exit-active {
  opacity: 0;
  width: 70px;
  transition: opacity 300ms, width 300ms;
  display: flex !important;
}
.sidebar-transition-exit-done {
  width: 0px;
  opacity: 0;
  display: none !important;
}

.sidebar-item {
  height: 15px;
  font-size: 2rem;
  padding: 20px 20px;
  color: #404349;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (min-width: 60em) {
  .sidebar {
    display: none;
  }
}
