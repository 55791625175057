.App-header {
  position: sticky;
  top: 0;
  background-color: #e4ded4;
  z-index: 1;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0px 0px rgb(0 0 0 / 30%), 0 5px 10px rgb(0 0 0 / 22%);
}
nav {
  display: none;
}

.App-nav-item {
  width: max-content;
  font-size: 1.5rem;
  color: #404349;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  position: relative;
}

.App-nav-item:hover {
  color: #f56565;
}

.active {
  font-weight: 700;
  cursor: default;
}
.active:hover {
  color: #404349;
}

.hambergur-menu {
  display: block;
  font-size: 4rem;
  padding-right: 10px;
  cursor: pointer;
  background: transparent;
  border: none;
  position: absolute;
  right: 10px;
  z-index: 1;
  transition: 0.5s;
}
.navbar-more-menu-container {
  position: relative;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop-more-menu {
  position: absolute;
  display: none;
  overflow: hidden;
  right: 0px;
  top: 40px;
  flex-wrap: wrap;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-image: linear-gradient(
    to bottom,
    rgba(204, 195, 176, 1) 0%,
    rgb(246, 246, 246) 21%,
    rgba(204, 195, 176, 1) 100%
  );
  width: 300px;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.desktop-line-link-wrapper {
  width: 40%;
  text-align: center;
}
.desktop-more-menu-item {
  font-size: 1.5rem;
  padding: 5px 0px 5px 0px;
  color: #404349;
  text-decoration: none;
}
.desktop-more-menu-item:hover {
  color: #cd6727;
}

.desktop-more-menu-transition-enter {
  display: flex !important;
  opacity: 0;
  height: 0px;
  padding: 0px;
}
.desktop-more-menu-transition-enter-active {
  display: flex !important;
  opacity: 1;
  height: 200px;
  padding: 20px;
  transition: height 300ms, display 300ms ease-in, padding 300ms;
}
.desktop-more-menu-transition-enter-done {
  opacity: 1;
  display: flex !important;
  height: 200px;
  padding: 20px;
}
.desktop-more-menu-transition-exit {
  opacity: 1;
  display: flex !important;
  height: 200px;
  padding: 20px;
}
.desktop-more-menu-transition-exit-active {
  opacity: 1;
  height: 0px;
  padding: 0px;
  transition: height 300ms, display 300ms ease-in, padding 300ms;
  display: flex !important;
}
.desktop-more-menu-transition-exit-done {
  opacity: 0;
  display: none !important;
  height: 0px;
  padding: 0px;
}

@media screen and (min-width: 60em) {
  nav {
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .hambergur-menu {
    display: none;
  }

  .dropdown .dropbtn {
    font-size: 1.5rem;
    border: none;
    outline: none;
    color: #404349;
    padding: 20px 0px;
    background-color: inherit;
    font-family: inherit;
  }

  .dropbtn:hover {
    color: #cd6727;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: max-content;
    padding: 10px 0px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 1.2rem;
  }
  .dropdown-content a:hover {
    background-color: #ddd;
    color: black;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}

@media screen and (min-width: 80em) {
  .App-nav-item {
    font-size: 2rem;
  }
  .navbar-more-menu-container span {
    font-size: 2rem;
  }

  .dropdown .dropbtn {
    font-size: 2rem;
  }

  .dropdown-content a {
    font-size: 1.5rem;
  }
}
