.more-menu-container {
  padding: 20px 20px;
  color: #404349;
}

.more-menu-container span {
  font-size: 2rem;
  text-decoration: none;
  cursor: pointer;
}

.more-menu {
  padding-top: 20px;
  padding-bottom: 20px;
  display: none;
  flex-direction: column;
  overflow: hidden;
  height: 0px;
}
.more-menu-item {
  height: 15px;
  font-size: 1.5rem;
  padding: 20px 0px 5px 40px;
  color: #404349;
  text-decoration: none;
}

.more-menu .line {
  margin-top: 20px;
}

.more-menu-transition-enter {
  display: flex !important;
  opacity: 0;
  height: 0px;
}
.more-menu-transition-enter-active {
  display: flex !important;
  opacity: 1;
  height: 100% !important;
  transition: opacity 500ms, height 500ms, display 500ms ease-in;
}
.more-menu-transition-enter-done {
  height: 100% !important;
  opacity: 1;
  display: flex !important;
}
.more-menu-transition-exit {
  opacity: 1;
  height: 100% !important;
  display: flex !important;
}
.more-menu-transition-exit-active {
  opacity: 1;
  height: 10% !important;
  transition: opacity 500ms ease-in, height 500ms ease-in, display 500ms ease-in;
  display: flex !important;
}
.more-menu-transition-exit-done {
  height: 0px !important;
  opacity: 0;
  display: none !important;
}
